<template>
  <div>
    <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2 dugme_stampa">
      <v-icon style="color:rgb(251, 109, 59)" class="mr-2">mdi-printer</v-icon>
      <span id="stampaj">{{ $t("print") }}</span>
    </v-btn>
    <v-tabs v-model="tab" background-color="rgb(251, 109, 59)" dark>
      <v-tab v-for="item in items" :key="item.tab" @click.prevent="printTable = item.print">
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        {{ translate(item.tab) }}
      </v-tab>
    </v-tabs>
    <v-tabs-items class="mt-5" v-model="tab">
      <v-tab-item>
        <v-data-table
        :headers="headersU"
        :items="users"
        sort-by="status"
        id="table5"
        class="elevation-1"
        dense
        hide-default-footer
        disable-pagination
        :search="pretraga"
      >
        <template v-for="header in headersU" v-slot:[`header.${header.value}`]="{ header }">
          {{ translate(header.text) }}
        </template>
        <template v-for="values in headersU" v-slot:[`item.${values.value}`]="{ item }">
          {{ translate(item[values.value]) }}
        </template>
        <template v-slot:item.id="{ item }">
          <span v-if="item.id == 'УКУПНО:'">{{ $t("totalU") }}</span>
          <span v-else @click.prevent="showUsers(item)">{{ item.id }} </span>
        </template>
        <template v-for="values in headersU" v-slot:item.num="{ item, index }">
          {{ item.id == 'УКУПНО' ? '' : index + 1 }}
        </template>
        <template v-slot:item.action="{ item }">
          <a :title="$t('months_report')"  v-if="item.id != 'УКУПНО:'" href="" @click.prevent="openDialogReportUser(item)">
            <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
          </a>
        </template>
      </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text style="display: flex;flex-wrap: wrap">
<!--            <v-autocomplete style="width: 70%"-->
<!--                            v-model="searchN"-->
<!--                            :items="users"-->
<!--                            dense-->
<!--                            filled-->
<!--                            :label="$t('select_company')"-->
<!--                            item-value="id"-->
<!--                            item-text="company"-->
<!--                            @change="findMealsNarudzbe()"-->
<!--                            clear-->
<!--                            chips-->
<!--                            small-chips-->
<!--            ></v-autocomplete>-->
<!--            <v-autocomplete style="width: 25%;margin-left: 10px"-->
<!--                            v-model="searchByShift"-->
<!--                            :items="narudzbe"-->
<!--                            dense-->
<!--                            filled-->
<!--                            :label="$t('shifts')"-->
<!--                            item-text="company"-->
<!--                            item-value="id"-->
<!--                            clear-->
<!--                            chips-->
<!--                            small-chips-->
<!--            ></v-autocomplete>-->
          </v-card-text>
          <div id="table34" style="display: flex;flex-wrap: wrap;">
            <div style="width: 32%;padding: 20px;border:1px solid #cfcfcf;border-radius: 5px;" v-for="(items, inx) in filterByShift">
              <p :title="'ID:' + items.id" class="text-center pt-2">
                <span style="color: #919191">#{{ items.id }}</span>
                <span class="font-weight-bold pt-2"> {{ items.name + ' ' + items.lastname }}</span>
              </p>
              <div style="display:flex;flex-wrap:wrap;" >
                <template v-if="items.orders && items.orders.length" v-for="(item, index) in items.orders">
                  <div style="">
<!--                    <template v-for="(i, inx) in item">-->
                      <p :title="'ID:' + item.food.id">{{ item.quantity }}<span style="color: #a4a4a4">x</span> {{ item.food.name }}</p>
<!--                    </template>-->
                  </div>
                </template>
              </div>
            </div>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>


    <v-dialog v-model="dialog" v-if="userReport.length">
      <v-card>
        <v-card-title style="display: flex;justify-content: space-between">
          Izvestaj {{ selected_user.name }} {{ selected_user.lastname }}
          <v-btn style="background: none;border: none;box-shadow: none" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('date') }}
                  </th>
                  <th class="text-left">
                    {{ $t('id_order') }}
                  </th>
                  <th class="text-left">
                    {{ $t('shifts')}}
                  </th>
                  <th class="text-left">
                    {{ $t('title') }}
                  </th>
                  <th class="text-left">
                    {{ $t('quantity') }}
                  </th>
                  <th class="text-left">
                    {{  $t('category') }}
                  </th>
                </tr>
              </thead>
              <tbody>
              <template v-for="(item, index1) in userReport">
                <template v-for="(order, index2) in item.orders">
                  <tr v-for="(food, index3) in order.foods">
                    <td v-if="index3 === 0" :rowspan="order.foods.length">
                      {{item.date}}
                    </td>
                    <td v-if="index3 === 0" :rowspan="order.foods.length" align="center">
                      {{food.id}}
                    </td>
                    <td>{{food.shift}}</td>
                    <td>{{food.meal_name}}</td>
                    <td>{{food.quantity}}</td>
                    <td>{{food.category_name}}</td>
                  </tr>
                </template>
              </template>

              <tr>
                <td class="font-weight-bold">
                  Ukupno
                </td>
                <td align="center" class="font-weight-bold">{{userReport.length}}</td>
                <td colspan="2"></td>
                <td class="font-weight-bold">
                  {{foodTotal}}
                </td>
                <td></td>
              </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Zatvori</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import axios from "../../plugins/axios";
import moment from "moment";

export default {
  props: {
    date: {
      required: true
    }
  },
  data() {
    return {
      tab: 0,
      users: [],
      searchN: "",
      searchByShift: "",
      items: [
        {
          tab: "Преглед корисника",
          content: "Tab 3 Content",
          print: "table5",
          icon: "mdi-account-settings",
        },
        {
          tab: 'НАРУЧЕНО',
          content: "Tab 4 Content",
          print: "table34",
          icon: "mdi-grid",
        },
      ],
      headersU: [
        { text: this.$t('r_b'), value: "num"},
        { text: "ID", value: "id"},
        { text: this.$t('name'), value: "name"},
        { text: this.$t('last_name'), value: "lastname"},
        { text: this.$t('ordered'), value: "order_count"},
        {
          text: this.$t('meals_main'),
          value: "meals"
        },
        { text: this.$t('taken'), value: "order_payed"},
        { text: this.$t('report'), value: "action"}
      ],
      dialogReport: false,
      userReportItem: null,
      userReportPlane: [],
      userReport: [],
      pretraga: "",
      narudzbe: "",
      dialog: false,
      printTable:'table5',
      selected_user: ''
    }
  },
  created() {
    this.getReport()
    this.$root.$on('getNewCompany', (data) => {
      this.narudzbe = ''
      this.users = data
      let shifts = []
      data.filter(item => {
        shifts.push(item.shift)
      })
      this.narudzbe = [... new Set(shifts)]
      this.$root.$emit('smene', this.narudzbe)
      this.searchByShift = this.narudzbe.length ? this.narudzbe[0] : []
    })
    this.$root.$on('pretragaSmena', (data) => {
      this.searchByShift = data
    })
  },
  methods: {
    print(id, type = false) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open(
          "",
          "",
          "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(
          `<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: #f1f1f1;
            }
            </style>

            </head>
            <body>
              ${this.$store.state.company.restaurant.name}: ${this.name}   ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>
          </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
    findMealsNarudzbe() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      axios.get(
          "/reports/daily/company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
          .then((res) => {
            let t = {}
            let korisnici = []
            for(let i in res.data) {
              if(i == 'company') {
                t[res.data.company.name] = res.data
              } else
              if(res.data[i].company) {
                t[res.data[i].company.name] = res.data[i]
              }
              if(res.data.meals) {
                res.data.meals.filter(meal => {
                  if (meal.name != 'УКУПНО') {
                    if (meal.users['1-smena']) {
                      meal.users['1-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 1,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                    if (meal.users['2-smena']) {
                      meal.users['2-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 2,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                    if (meal.users['3-smena']) {
                      meal.users['3-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 3,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                  }
                })
              }
            }

            let podaci = []
            let smena = this.groupBy(korisnici, 'shift')
            let priprema = {}
            let pr = {}
            for (let i in smena) {
              priprema[i] = this.groupBy(smena[i], 'user_id')
              pr[i] = {}
              for (let n in priprema[i]) {
                // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
                pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
              }
            }
            this.narudzbe = pr
            this.searchByShift = Object.keys(this.narudzbe)[0]
          });
    },
    openDialogReportUser(item, food) {
      this.selected_user = item
      this.$root.$emit('openDialog', item)
      axios.post('/user/food-orders-by-month', {
        month: moment(this.date[0]).format("MM"),
        year: moment(this.date[0]).format("YYYY"),
        user_id: item.id
      }).then(res => {

        let orders = []
        res.data.forEach(orderItem => {
          let findDate = orders.find(i => i.date === orderItem.date)

          if(findDate) {
            let findOrder = findDate.orders.find(i => i.id === orderItem.id)
            if(findOrder) {
              findOrder.foods = [...findOrder.foods, orderItem]
            }
            else {
              findOrder.foods = [orderItem]
            }
          }
          else {
            orders.push({
              date: orderItem.date,
              orders: [{
                id: orderItem.id,
                foods: [orderItem]
              }]
            })
          }
        })

        this.userReportPlane = res.data
        this.userReport = orders
      }).then(() => {
        this.dialog = true
      })
    },
    getReport() {
      const startAt = moment(this.date[0]).format("YYYY-MM-DD")
      const endAt = moment(this.date[1]).format("YYYY-MM-DD")
      const url =
        '/reports/daily/company/' + this.company.id +
        '/food-korisnici?date='+  startAt +
        '&date2=' +  endAt

      axios.get(url).then((res)=>{

        let order_count_total = 0;
        let order_payed_total = 0;
        let order_meals_total = 0;

        res.data.users.forEach(user => {
          order_count_total += user.order_count
          order_payed_total += user.order_payed
          order_meals_total += user.meals
        })
        res.data.users.push({
          'id': 'УКУПНО:',
          'order_count': order_count_total,
          'order_payed': order_payed_total,
          'meals': order_meals_total
        })

        this.users = res.data.users;

      })
    }
  },
  computed: {
    filterByShift() {
      let podaci = []
      this.users.filter(item => {
        if (item.shift == this.searchByShift && item.order_count > 0) {
          podaci.push(item)
        }
      })
      return podaci;
    },
    company() {
      return this.$store.state.company
    },
    foodTotal() {
      return this.userReportPlane.reduce((sum, item) => {
        return sum + item.quantity
      }, 0)
    },
    desserts4() {
      let arr2 = [];
      if (this.users) {
        for (let company in this.users) {
          arr2.push({ company: company, foods: []});
          for (let shift in this.report2[company]) {
            for (let shift2 in this.report2[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex(
                  (i) => i.name === shift2
              );
              if (index2 > -1) {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods[index2].shift_one +=
                    shift === "1-smena"
                        ? this.report2[company][shift][shift2].quantity
                        : 0;
                arr2[index].foods[index2].shift_one_payed +=
                    shift === "1-smena"
                        ? this.report2[company][shift][shift2].quantity_payed
                        : 0;
                arr2[index].foods[index2].shift_two +=
                    shift === "2-smena"
                        ? this.report2[company][shift][shift2].quantity
                        : 0;
                arr2[index].foods[index2].shift_two_payed +=
                    shift === "2-smena"
                        ? this.report2[company][shift][shift2].quantity_payed
                        : 0;
                (arr2[index].foods[index2].shift_three +=
                    shift === "3-smena"
                        ? this.report2[company][shift][shift2].quantity
                        : 0),
                    (arr2[index].foods[index2].shift_three_payed +=
                        shift === "3-smena"
                            ? this.report2[company][shift][shift2].quantity_payed
                            : 0),
                    (arr2[index].foods[index2].total += this.report2[company][
                        shift
                        ][shift2].quantity),
                    (arr2[index].foods[index2].total_payed += this.report2[company][
                        shift
                        ][shift2].quantity_payed);
              } else {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods.push({
                  name: shift2,
                  id: this.report2[company][shift][shift2].id,
                  shift_one:
                      shift === "1-smena"
                          ? this.report2[company][shift][shift2].quantity
                          : 0,
                  shift_one_payed:
                      shift === "1-smena"
                          ? this.report2[company][shift][shift2].quantity_payed
                          : 0,
                  shift_two:
                      shift === "2-smena"
                          ? this.report2[company][shift][shift2].quantity
                          : 0,
                  shift_two_payed:
                      shift === "2-smena"
                          ? this.report2[company][shift][shift2].quantity_payed
                          : 0,
                  shift_three:
                      shift === "3-smena"
                          ? this.report2[company][shift][shift2].quantity
                          : 0,
                  shift_three_payed:
                      shift === "3-smena"
                          ? this.report2[company][shift][shift2].quantity_payed
                          : 0,
                  total: this.report2[company][shift][shift2].quantity,
                  total_payed: this.report2[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
            total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
          });
        });
      }

      return arr2;
    },
  },
  watch: {
    date() {
      this.getReport()
    },
    tab() {
      this.$root.$emit('changedTab', this.tab)
    }
  }
}

</script>

<style scoped>
.dugme_stampa {
  position: absolute;top:20px;right: 200px;
}
tbody tr:hover {
  background-color: transparent !important;
}

tbody td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
@media (min-width: 1000px) and (max-width: 1160px) {
  .dugme_stampa {
    right: 80px;
  }
}
@media (min-width: 1100px) and (max-width: 1263px) {
  .dugme_stampa {
    right: 120px;
  }
}
@media (min-width: 1264px) and (max-width: 1299px) {
  .dugme_stampa {
    right: 100px;
  }
}
@media (min-width: 1300px) and (max-width: 1390px){
  .dugme_stampa {
    right: 100px;
  }
}
</style>
