<template>
  <v-container>
    <v-row>
      <v-col :cols="tab == 1 ? 4 : 4">
        <v-menu style="width: 292px !important;"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                min-width="290px"
                offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                :label="$t('date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
            ></v-text-field>
          </template>
          <v-card style="padding:10px;width: 292px !important;">
            <strong>{{$t("select_span_meals_listing")}}</strong><br>
            {{$t("click_on_two_dates")}}<br>
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="
                $refs.menu.save(date);
                load();
              "
              >
                {{ $t("confirm") }}
              </v-btn>
            </v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col :cols="tab == 1 ? 4 : 5">
        <v-select
          v-model="company.id"
          v-if="companies.length>1"
          :items="companies"
          item-text="name"
          item-value="id"
          :label="$t('select_branch')"
          @input="changeCompany"
          hide-details
        ></v-select>
      </v-col>
      <v-col v-if="tab == 1 && $store.state.user.type != 'company'" cols="4">
        <v-autocomplete style="padding-top: 13px;width: 55%"
                        v-model="pretragaSmena"
                        :items="smene"
                        :label="$t('shifts')"
                        item-text="comp any"
                        item-value="id"
                        clear
                        chips
                        small-chips
        ></v-autocomplete>
        <v-btn v-if="$store.state.user.type != 'company'" :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2">
          <v-icon style="color:rgb(251, 109, 59)" class="mr-2">mdi-printer</v-icon>
          <span id="stampaj">
            {{ $t("print") }}
          </span>
        </v-btn>
      </v-col>
      <v-col v-if="tab == 3 && $store.state.user.type == 'company'" cols="3">
        <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2">
          <v-icon style="color:rgb(251, 109, 59)" class="mr-2">mdi-printer</v-icon>
          <span id="stampaj">
            {{ $t("print") }}
          </span>
        </v-btn>
      </v-col>
<!--      <v-col sm="12" lg="2" class="mt-2">-->

<!--      </v-col>-->
<!--      <v-col cols="12" v-if="$store.state.company.parent_id">-->
<!--        <v-dialog-->
<!--          v-model="dialog"-->
<!--          width="70vw"-->
<!--          v-if="$store.state.company.parent_id"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--&lt;!&ndash;            <v-btn class="primary ma-2" v-bind="attrs" v-on="on">&ndash;&gt;-->
<!--&lt;!&ndash;              <v-icon class="mr-2">mdi-format-list-bulleted</v-icon&ndash;&gt;-->
<!--&lt;!&ndash;              >{{ $t("display_check_in_check_outе") }}&ndash;&gt;-->
<!--&lt;!&ndash;            </v-btn>&ndash;&gt;-->
<!--          </template>-->
<!--          <v-card>-->
<!--            <v-card-title class="headline grey lighten-2">-->
<!--              {{ $t("location_change") }}-->
<!--            </v-card-title>-->
<!--            <v-card-text>-->
<!--              <v-data-table-->
<!--                :headers="headers"-->
<!--                :items="changes"-->
<!--                :search="search"-->
<!--                sort-by="status"-->
<!--                class="elevation-1"-->
<!--                dense-->
<!--                hide-default-footer-->
<!--                disable-pagination-->
<!--              >-->
<!--                <template v-slot:top>-->
<!--                  <v-toolbar flat>-->
<!--                    <v-text-field-->
<!--                      class="pr-4 pb-2"-->
<!--                      v-model="search"-->
<!--                      append-icon="mdi-magnify"-->
<!--                      :label="$t('search')"-->
<!--                      single-line-->
<!--                      hide-details-->
<!--                      dense-->
<!--                    ></v-text-field>-->
<!--                  </v-toolbar>-->
<!--                </template>-->
<!--                <template v-slot:no-data>-->
<!--                  {{ $t("no_location_change_selected_date") }}-->
<!--                </template>-->
<!--                <template-->
<!--                  v-for="header in headers"-->
<!--                  v-slot:[`header.${header.value}`]="{ header }"-->
<!--                >-->
<!--                  {{ translate(header.text) }}-->
<!--                </template>-->
<!--                <template-->
<!--                  v-for="values in headers"-->
<!--                  v-slot:[`item.${values.value}`]="{ item }"-->
<!--                >-->
<!--                  {{ translate(item[values.value]) }}-->
<!--                </template>-->
<!--              </v-data-table>-->
<!--            </v-card-text>-->

<!--            <v-divider></v-divider>-->

<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn color="primary" text @click="dialog = false">-->
<!--                {{ $t("close") }}-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-dialog>-->
<!--      </v-col>-->
      <v-card v-if="$store.state.company && $store.state.company.type !== 'group'" style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="rgb(251, 109, 59)" dark>
          <v-tab @click="printTable = item.print;" style="width: 33%" v-for="item in items" :key="item.tab">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items class="mt-5" v-model="tab">
          <v-tab-item>
            <v-card id="table3" flat>
              <template v-for="(comapny, companyKey) in desserts4">
                <h2>{{ translate(comapny.company)+translate(": оброци") }}</h2>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("meal") }}
                        </th>
                        <template v-if="$store.state.company.shift_one">
                          <th class="text-center">
                            {{ $t("1st_shift") }}
                          </th>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <th class="text-center">
                            {{ $t("2nd_shift") }}
                          </th>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <th class="text-center">
                            {{ $t("3rd_shift") }}
                          </th>
                        </template>
                        <th class="text-center">
                          {{ $t("total") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item,index) in comapny.foods" :key="index">
                        <td @click="showMeal(item.id)">{{ translate(item.name) }}</td>
                        <template v-if="$store.state.company.shift_one">
                          <td class="text-center px-4">
                            {{ translate(item.shift_one) }}
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <td class="text-center px-4">
                            {{ translate(item.shift_two) }}
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <td class="text-center px-4">
                            {{ translate(item.shift_three) }}
                          </td>
                        </template>
                        <td class="text-center px-4">
                          {{ translate(item.total) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card id="table4" flat>
              <template v-for="(comapny, companyKey) in desserts4">
                <h2>{{translate(comapny.company) + $t("meals_and_employees") }}
                </h2>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("meal") }}
                        </th>
                        <template v-if="$store.state.company.shift_one">
                          <th class="text-center">{{ $t("1st_shift") }}</th>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <th class="text-center">{{ $t("2nd_shift") }}</th>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <th class="text-center">{{ $t("3rd_shift") }}</th>
                        </template>
                        <th class="text-center">
                          {{ $t("total") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, inx) in meals" :key="inx">
                        <td class="text-left" @click="showMeal(item.id)">
                          {{ translate(item["name"]) }}
                        </td>
                        <template v-if="$store.state.company.shift_one">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["1-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['1-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 0
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["2-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['2-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 1
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["3-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['3-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 1
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <td class="text-center">
                          {{ item["total"] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card id="table5" flat>
               <template v-for="(comapny, companyKey) in desserts4">
                <h2>{{translate(comapny.company) + translate(": радници") }}
                </h2>
              <v-data-table
                :headers="headers2"
                :items="users"
                sort-by="status"
                class="elevation-1"
                dense
                hide-default-footer
                disable-pagination
              >
                <template
                  v-for="header in headers"
                  v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ translate(header.text) }}
                </template>
                <template
                  v-for="values in headers"
                  v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template
                  v-for="values in headers"
                  v-slot:item.num="{ item, index }"
                >
                  {{ index + 1 }}
                </template>
                <template v-slot:item.action="{ item }">
                  <a v-if="item.id != 'УКУПНО:'" href="" @click.prevent="openDialogReportUser1(item)">
                    <v-icon style="font-size: 17px" color="rgb(251, 109, 59)">mdi-format-list-bulleted</v-icon>
                  </a>
                </template>
              </v-data-table>
               </template>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="show_rep">
            <v-card id="table1">
              <template v-for="(comapny, companyKey) in desserts4">
                <v-data-table
                  :headers="headers3"
                  :items="comapny.foods"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                >
                  <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                    v-for="values in headers"
                    v-slot:[`item.${values.value}`]="{ item }"
                  >
                    {{ translate(item[values.value]) }}
                  </template>

                  <template v-slot:item.shift_one="{item}">
                    {{item.shift_one_payed}}/{{item.shift_one}}
                  </template>
                  <template v-slot:item.shift_two="{item}">
                    {{item.shift_two_payed}}/{{item.shift_two}}
                  </template>
                  <template v-slot:item.shift_three="{item}">
                   {{item.shift_three_payed}}/{{item.shift_three}}
                  </template>
                  <template v-slot:item.total="{item}">
                    {{item.total_payed}}/{{item.total}}
                  </template>
                  -->
                  <template v-slot:item.menu="{ item }">
                    <span @click="showMeal(item.id)">{{
                      translate(item.name)
                    }}</span>
                  </template>
                  <template
                    v-slot:item.num="{ item, index }"
                  >
                    {{ item.name!== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="show_orders">
            <v-card flat id="table36">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%"
                                v-model="searchN"
                                :items="desserts4.filter((i) => i.company)"
                                dense
                                filled
                                :label="$t('select_company')"
                                item-value="id"
                                item-text="company"
                                @change="findMealsNarudzbe()"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                                v-model="searchByShift"
                                :items="Object.keys(narudzbe)"
                                dense
                                filled
                                :label="$t('shifts')"
                                item-text="company"
                                item-value="id"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
              </v-card-text>
              <div id="table34" style="display: flex;flex-wrap: wrap;">
                <div :style="Object.keys(searcByShift).length > 1 ? 'width: 32%;' : 'width: 100%;'" v-for="(items, inx) in searcByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }} (<v-icon>mdi-user</v-icon> {{ Object.keys(items).length }})
                  </p>
                  <div :style="Object.keys(searcByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'" >
                    <template v-for="(item, index) in items">
                      <div :style="Object.keys(searcByShift).length > 1 ? '' : 'width: 32%;'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 5px;">
                        <p :title="'ID:' + item[Object.keys(item)[0]][0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[Object.keys(item)[0]][0].order_id }}</span>
                          <span class="font-weight-bold pt-2"> {{ index }}</span>
                        </p>
                        <template v-for="(i, inx) in item">
                          <p :title="'ID:' + item[inx][0].meal_id">{{ item[inx][0].quantity }}<span style="color: #a4a4a4">x</span> {{ inx }}</p>
                        </template>

                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-col v-else cols="12">
        <group-company-reports :date="date"></group-company-reports>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          $store.state.company.restaurant_id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{ translate(selectedMeal.name) }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span><h3>{{ translate(eat.name) }}</h3></span>
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog2 = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedUser.image_path
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          selectedUser.image_path
                        : 'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{
                            translate(selectedUser.name) +
                            " " +
                            translate(selectedUser.lastname)+
                            ' ('+selectedUser.id+')'
                          }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <v-col cols="12" class="font-weight-bold pt-2">
                            <v-row no-gutters justify="space-between">
                              <span
                                ><h3>{{ selectedUser.email }}</h3></span
                              >
                            </v-row>
                          </v-col>
                          <v-col cols="12"
                            >{{ $t("select_notifications") }}
                            {{
                              selectedUser.notifications
                                ? translate(selectedUser.notifications)
                                : $t("not_available")
                            }}</v-col
                          >
                          <v-col cols="12"> {{ selectedUser.phone }}</v-col>
                          <v-col cols="12">
                            {{ selectedUser.external_code }}</v-col
                          >
                          <v-col cols="12">
                            {{ selectedUser.address }}
                          </v-col>
                          <v-col cols="12">
                            {{
                              selectedUser.is_transferable
                                ? $t("can_change_location")
                                : ""
                            }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReport" max-width="90%" scrollable class="dialogReport">
      <v-container style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card>
              <div style="background-color: rgb(255, 248, 245);box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);padding: 10px;display: flex;align-content: center;height: 64px">
                <div style="display: flex;align-items: center;width: 40%">
                  <img src="https://restorani.bitlab.host/img/o.png" alt="image" style="width: 54px;">
                  <div style="font-size: 15px;font-weight: 700;padding-left: 20px">{{ userReportItem.name }} {{ userReportItem.lastname }}, ID {{ userReportItem.id }}</div>
                </div>
                <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogReport = false">
                  <v-icon x-medium>mdi-close</v-icon>
                </v-btn>
                <v-card-text align="center" class="title" style="width: 50%;text-align: left;display: flex;align-content: center;margin-top: -7px">
                  <span style='color:#fb6d3b'>
                    <v-icon style='color:#fb6d3b' small>mdi-calendar</v-icon> {{ monthName }} {{ year }}.
                  </span>
                </v-card-text>
              </div>
              <v-card-title style="padding: 6px 0 18px 0;display: flex">
                <div style="width: 60%;display: flex">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date1"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field  style="margin: 40px 0;padding: 0 10px"
                         v-model="date1"
                         label="Picker in dialog"
                         prepend-inner-icon="mdi-calendar"
                         readonly
                         v-bind="attrs"
                         v-on="on"
                         hide-details
                         class="px-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" type="month" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date1), loadReport(userReportItem)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field style="margin: 40px 0;padding: 0 10px"
                    v-model="searchR"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="px-2"
                  ></v-text-field>
                </div>
                <div style="width: 40%;display: flex;align-content: center">
                  <v-checkbox style="padding: 0 10px"
                    label="Prikazi sve"
                    hide-details
                    v-model="allDay"
                    @change="filterDay"
                  >
                  </v-checkbox>
                </div>
              </v-card-title>
              <v-simple-table id="table222" class="py-2">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">{{ $t('date')}}</th>
                    <th class="text-left">Smene</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="item in reportSearch">
                    <tr>
                      <td>{{item.day}}</td>
                      <td>
                        <table width="100%">
                          <tr v-if="item.shiftOne && item.shiftOne.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('1st_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftOne">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="item.shiftTwo && item.shiftTwo.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('2nd_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftTwo">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="item.shiftThree && item.shiftThree.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('3rd_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftThree">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="title">{{ $t('totalU')}}:</td>
                    <td class="title" align="end">
                      <span class="mr-15 pr-4">{{ total3 }}</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";
import conf from "../config";
import groupCompanyReports from '../components/groupCompany/reports'

export default {
  components: {groupCompanyReports},
  data() {
    return {
      moment: moment,
      allDay: false,
      pretragaSmena: '',
      smene: [],
      searchN: '',
      narudzbe: '',
      conf: conf,
      date: [new Date().toISOString().substr(0, 10)],
      date1: new Date().toISOString().substr(0, 10),
      menu: false,
      search: "",
      searchByShift: "",
      report: null,
      changes: null,
      dialog: false,
      printTable: 'table3',
      meals: null,
      users: [],
      tab: null,
      selCompany:null,
      dialog2: false,
      modal: false,
      companies:[],
      company:{
        id:null,
        name:null,
      },
      selectedMeal: {
        foods: [],
        name: "",
        image: {
          name: "",
        },
      },
      selectedUser: {
        name: "",
        lastname: "",
        image_path: "",
      },
      items: [
         {tab: "Преглед наруџби", content: "Tab 1 Content", print: "table3", icon: "mdi-clipboard-list-outline",},
        { tab: "Оброк-радник", content: "Tab 2 Content", print: "table4", icon: "mdi-food" },
        { tab: "Преглед корисника", content: "Tab 3 Content", print: "table5", icon: "mdi-account-settings",},
      ],
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },

        {
          text: "Име",
          align: "start",
          value: "name",
        },
        {
          text: "Презиме",
          align: "start",
          value: "lastname",
        },
        {
          text: "Долази из",
          align: "start",
          value: "from",
        },
        {
          text: "Долази у",
          align: "end",
          value: "to",
        },
      ],
      headers2: [
        {text: "Р.Бр.", value: "num",},
        {text: this.$t('name'), value: "name",},
        {text: this.$t('last_name'), value: "lastname",},
        {text: this.$t('ordered'), value: "order_count",},
        {text: this.$t('report'), value: "action",},
      ],
      headers3: [
        {
          text: "Р.Бр.",
          value: "num",
        },
        { text: "Оброк", value: "menu" },
        { text: "Смјена 1", value: "shift_one" },
        { text: "Смјена 2", value: "shift_two" },
        { text: "Смјенa 3", value: "shift_three" },
        { text: "Укупно", value: "total" },
      ],
      dessertsReport: [],
      dessertsR: [],
      dessertsReportAll: [],
      dialogReport: false,
      userReportItem: '',
      searchR: '',
      show_orders: false,
      show_rep: false
    };
  },
  watch: {
    pretragaSmena() {
      this.$root.$emit('pretragaSmena', this.pretragaSmena)
    }
  },
  created() {
    this.$root.$on('changedTab', (tab) => {
      this.tab = tab
    })
    this.$root.$on('smene', (smene) => {
      this.smene = smene
      this.pretragaSmena = smene[0]
    })
    this.$root.$on('openDialog', (data) => {
      this.userReportItem = data
      // this.dialogReport = true
    })
    this.company.id=this.$store.state.company.id;
    this.company.name=this.$store.state.company.name;
    axios.get("/companies/list/"+this.company.id).then((res)=>{
      res.data.forEach(el=>{
        this.companies.push({'id':el[0],'name':el[1]});
      })
    })
    if(this.$store.state.company.checkReport){
      this.show_rep = true
        this.items.push({ tab: "Преузети оброци", content: "Tab 4 Content", print: 'table1', icon: "mdi-check-underline-circle" })
    }

    if(this.$store.state.company.settings && JSON.parse(this.$store.state.company.settings).can_see_report){
      this.show_orders = true
      this.items.push({ tab: 'НАРУЧЕНО', content: "Tab 35 Content", print: "table36", icon: "mdi-grid",},)
    }
    this.load();
  },
  methods: {
    findMealsNarudzbe() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      axios.get(
          "/reports/daily/company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
        .then((res) => {
          let t = {}
          let korisnici = []
          for(let i in res.data) {
            if(i == 'company') {
              t[res.data.company.name] = res.data
            } else
            if(res.data[i].company) {
              t[res.data[i].company.name] = res.data[i]
            }

            if(res.data[i].meals) {
              res.data[i].meals.filter(meal => {
                if (meal.name != 'УКУПНО') {
                  if (meal.users['1-smena']) {
                    meal.users['1-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['2-smena']) {
                    meal.users['2-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 2,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['3-smena']) {
                    meal.users['3-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 3,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                }
              })
            }
          }

          let podaci = []
          let smena = this.groupBy(korisnici, 'shift')
          let priprema = {}
          let pr = {}
          for (let i in smena) {
            priprema[i] = this.groupBy(smena[i], 'user_id')
            pr[i] = {}
            for (let n in priprema[i]) {
              // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
              pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
            }
          }
          this.narudzbe = pr
          this.searchByShift = Object.keys(this.narudzbe)[0]
        });
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    filterDay() {
      if(this.allDay) {
        this.dessertsReport = [...this.dessertsReportAll]
      }
      else {
        this.dessertsReport = this.dessertsReportAll.filter(item => {
          if(item.shiftOne && item.shiftOne.length) {
            return item
          }
          if(item.shiftTwo && item.shiftTwo.length) {
            return item
          }
          if(item.shiftThree && item.shiftThree.length) {
            return item
          }
        })
      }
      this.$forceUpdate()
    },
    loadReport(item) {
      this.loading = true
      axios.post('/user/orders-by-month', {
        month: this.month,
        year: this.year,
        user_id: item.id
      }).then(res => {
        this.dessertsR = res.data
        this.report3()
      }).finally(() => {
        this.loading = false
      })
    },
    report3() {
      this.dessertsReport = []
      for(let day = 1; day <= this.daysInMonth; day++) {
        let checks = this.dessertsR.filter(item => {
          return item.date === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if(checks.length) {
          checks.forEach(check => {
            let index = this.dessertsReport.findIndex(item => item.day === day)
            if(check.shift === 1) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftOne) this.dessertsReport[index].shiftOne = []
                this.dessertsReport[index].shiftOne.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftOne: [check]})
              }
            }
            else if (check.shift === 2) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftTwo) this.dessertsReport[index].shiftTwo = []
                this.dessertsReport[index].shiftTwo.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftTwo: [check]})
              }
            }
            else if (check.shift === 3) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftThree) this.dessertsReport[index].shiftThree = []
                this.dessertsReport[index].shiftThree.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftThree: [check]})
              }
            }
          })
        }
        else {
          this.dessertsReport.push({day, shiftOne: [], shiftTwo: [], shiftThree: []})
        }
      }
      this.dessertsReportAll = [...this.dessertsReport]
      this.filterDay()
    },
    openDialogReportUser1(item) {
      this.dialogReport = true
      console.log(item)
      this.userReportItem = item
      this.printTable = 'table11'
      this.loadReport(item)
    },
    changeCompany(){
      this.load()
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
        // console.log(this.selectedMeal);
      });
    },
    showUsers(user) {
      this.selectedUser = user;
      //console.log(this.selectedUser);
      this.dialog2 = true;
    },
    getReport() {
      const startAt = moment(this.date[0]).format("YYYY-MM-DD")
      const endAt = moment(this.date[1]).format("YYYY-MM-DD")
      const url =
          '/reports/daily/company/' + this.company.id +
          '/food-korisnici?date='+  startAt +
          '&date2=' +  endAt

      axios.get(url).then((res)=>{

        let order_count_total = 0;
        let order_payed_total = 0;
        let order_meals_total = 0;

        res.data.users.forEach(user => {
          order_count_total += user.order_count
          order_payed_total += user.order_payed
          order_meals_total += user.meals
        })
        res.data.users.push({
          'id': 'УКУПНО:',
          'order_count': order_count_total,
          'order_payed': order_payed_total,
          'meals': order_meals_total
        })

        this.$root.$emit('getNewCompany', res.data.users)

      })
    },
    load() {
      if(this.date.length==1){
        this.date.push(this.date[0]);
      }
      if(this.date[0]>this.date[1])[this.date[0],this.date[1]]=[this.date[1],this.date[0]];
      axios
        .get(
          "/reports/daily/company-new/" +
            this.company.id +
            "?date=" +
            moment(this.date[0]).format("YYYY-MM-DD")+
            "&date2="+
            moment(this.date[1]).format("YYYY-MM-DD")
        )
        .then((res) => {
          this.report = res.data;
        });
      axios
        .get(
          "/reports/daily/company/" +
            this.company.id +
            "/location?date=" +
            moment(this.date[0]).format("YYYY-MM-DD")+
            "&date2="+
            moment(this.date[1]).format("YYYY-MM-DD")
        )
        .then((res) => {
          this.changes = res.data;
           console.log(res.data);
        });
      axios
        .get(
          "/reports/daily/company/" +
            this.company.id +
            "/userlist?date=" +
            moment(this.date[0]).format("YYYY-MM-DD")+
            "&date2="+
            moment(this.date[1]).format("YYYY-MM-DD")
        )
        .then((res) => {
          this.meals = res.data.meals;
          this.users = res.data.users;
          // let sm = []
          // let smen = {
          //   '1-smena': 1,
          //   '2-smena': 2,
          //   '3-smena': 3
          // }
          // for(let i in res.data.meals[res.data.meals.length - 1].users) {
          //   if (res.data.meals[res.data.meals.length - 1].users[i] > 0) {
          //     sm.push(smen[i])
          //   }
          // }
          // this.smene = sm;
        });
      this.getReport()
    },
    print(id, type = false) {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open(
            "",
            "",
            "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(
            `<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: #f1f1f1;
            }
            </style>

            </head>
            <body>
              ${this.$store.state.company.restaurant.name}: ${this.name}   ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>
          </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();

    },
  },
  computed: {
    searcByShift() {
      let byShift = {}
      if (this.searchByShift) {
        byShift[this.searchByShift] = {}
        byShift[this.searchByShift] = this.narudzbe[this.searchByShift]
      }
      return byShift
    },
    daysInMonth() {
      return this.moment().month(this.month).daysInMonth()
    },
    reportSearch() {
      let data = []
      if (this.searchR != '') {
        this.dessertsReport.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }
      console.log(data)
      return data.length ? data : this.dessertsReport
    },
    total3() {
      let total = 0
      this.dessertsReport.forEach(item => {
        if(item.shiftOne && item.shiftOne.length) {
          item.shiftOne.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftTwo && item.shiftTwo.length) {
          item.shiftTwo.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftThree && item.shiftThree.length) {
          item.shiftThree.forEach(menu => {
            total += menu.quantity
          })
        }
      })
      return total
    },
    monthName() {
      return this.moment(this.month, "M").format('MMMM').toUpperCase()
    },
    month() {
      return parseInt(this.date1.split('-')[1])
    },
    year() {
      return parseInt(this.date1.split('-')[0])
    },
    dateRangeText () {
      if(this.date.length==1||this.date[0]==this.date[1]){
        return moment(this.date[0]).format("DD.MM.YYYY");
      }
      return moment(this.date[0]).format("DD.MM.YYYY")+' - '
            +
      moment(this.date[1]).format("DD.MM.YYYY");

    },
    desserts4() {
      let arr2 = [];
      if (this.report) {
        for (let company in this.report) {
          arr2.push({ company: company, foods: [] });
          for (let shift in this.report[company]) {
            for (let shift2 in this.report[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex(
                (i) => i.name === shift2
              );
              if (index2 > -1) {
                arr2[index].foods[index2].shift_one +=
                  shift === "1-smena"
                    ? this.report[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_one_payed +=
                  shift === "1-smena"
                    ? this.report[company][shift][shift2].quantity_payed
                    : 0;
                arr2[index].foods[index2].shift_two +=
                  shift === "2-smena"
                    ? this.report[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_two_payed +=
                  shift === "2-smena"
                    ? this.report[company][shift][shift2].quantity_payed
                    : 0;
                (arr2[index].foods[index2].shift_three +=
                  shift === "3-smena"
                    ? this.report[company][shift][shift2].quantity
                    : 0),
                    (arr2[index].foods[index2].shift_three_payed +=
                  shift === "3-smena"
                    ? this.report[company][shift][shift2].quantity_payed
                    : 0),
                  (arr2[index].foods[index2].total += this.report[company][
                    shift
                  ][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += this.report[company][
                    shift
                  ][shift2].quantity_payed);
              } else {
                arr2[index].foods.push({
                  name: shift2,
                  id: this.report[company][shift][shift2].id,
                  shift_one:
                    shift === "1-smena"
                      ? this.report[company][shift][shift2].quantity
                      : 0,
                  shift_one_payed:
                    shift === "1-smena"
                      ? this.report[company][shift][shift2].quantity_payed
                      : 0,
                  shift_two:
                    shift === "2-smena"
                      ? this.report[company][shift][shift2].quantity
                      : 0,
                  shift_two_payed:
                    shift === "2-smena"
                      ? this.report[company][shift][shift2].quantity_payed
                      : 0,
                  shift_three:
                    shift === "3-smena"
                      ? this.report[company][shift][shift2].quantity
                      : 0,
                  shift_three_payed:
                    shift === "3-smena"
                      ? this.report[company][shift][shift2].quantity_payed
                      : 0,
                  total: this.report[company][shift][shift2].quantity,
                  total_payed: this.report[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }
        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }

      return arr2;
    },
  },

};
</script>
