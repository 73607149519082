<template>
  <div>
    <v-app
      id="inspire"
      v-if="
        $route.name !== 'logovanje' &&
        $route.name !== 'zaboravljena-lozinka' &&
        $route.name !== 'resetovanje-lozinke'
      "
    >
      <v-navigation-drawer
        v-if="menu.length > 1 && $store.state.user && ($store.state.restaurant || $store.state.company) && $store.state.user.type != 'recipient' && $store.state.user.type != 'guest' && $store.state.user.type != 'customer' && $store.state.user.type != 'company'"
        v-model="drawer"
        app
        clipped
      >
        <v-list dense nav>
          <v-row class="mb-5" style="background-color: #f3f4fc" v-if="windowSize.x < 600">
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <!--<v-btn
                          text
                          v-bind="attrs"
                          v-on="on"
                  >
                    <v-icon>mdi-account</v-icon>
                    Profile
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>-->
                  <v-avatar color="primary" size="62" v-bind="attrs" v-on="on">
                    <span class="white--text">
                      {{
                        translate($store.state.user.name.charAt(0).toUpperCase()) +
                        translate($store.state.user.lastname.charAt(0).toUpperCase())
                      }}
                    </span>
                  </v-avatar>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in [
                      { title: 'Профил', icon: 'mdi-account',to:{ name: 'profil' } },
                      { title: 'QR код', icon: 'mdi-qrcode ', to: { name: 'qrShow' } },
                      { title: 'Инфо', icon: 'mdi-information ', to: { name: 'info' } },
                    ]"
                    :key="index"
                    @click=""
                  >
                    <v-list-item-title
                      @click="$router.push(item.to)"
                    >
                      <v-icon v-html="item.icon" small class="pr-2"></v-icon>
                      {{ translate(item.title)  }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col>
              <v-row>
                <v-col cols="12" class="pb-0 pt-4 caption">{{translate($store.state.user.type) }}</v-col>
                <v-col cols="12" class="pt-0">{{ translate($store.state.user.name) }} {{ translate($store.state.user.lastname) }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="my-4">
              <v-select
                  v-if="canSeeLanguage"
                  class="mr-2 jezik"
                  v-model="$i18n.locale"
                  :items="newSelectedRestoran && newSelectedRestoran.settings.language ? newSelectedRestoran.settings.language : [
                    {code: 'sr', name: 'Српски - ћирилица', cir_to_lat: 0},
                    {code: 'sr-lat', name: 'Srpski - Latinica', cir_to_lat: 1},
                    {code: 'rs', name: 'Ћирилица', cir_to_lat: 0},
                    {code: 'rs-lat', name: 'Latinica', cir_to_lat: 1},
                    {code: 'hr', name: 'Hrvatski', cir_to_lat: 1},
                    {code: 'bs', name: 'Bosanski', cir_to_lat: 1},
                    {code: 'en', name: 'English', cir_to_lat: 0},
                    {code: 'mk', name: 'Македонски', cir_to_lat: 0},
                    {code: 'si', name: 'Slovenščina', cir_to_lat: 0},
                  ]"
                  hide-details
                  dense
                  item-value="code"
                  item-text="name"
                  @change="setLang"
              ></v-select>
              <v-btn class="primary mx-4 mt-4" @click="changeLang()">
                {{ $store.state.isLatin ? "Ћир" : "Lat" }}
              </v-btn>
              <v-btn class="primary mx-4 mt-4" @click="logout()">
                {{ $t('logout')}}
              </v-btn>
              <v-spacer v-if="websites.length"></v-spacer>
            </v-col>
          </v-row>
          <template v-for="item in menu">
            <template v-if="item.child">
              <v-list-group no-action value="true">
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-for="child in item.child">
                  <v-list-item :to="child.to" link exact>
                    <v-list-item-content>
                      <v-list-item-title>{{ translate(child.title) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
            <!-- SIDEBAR-->
            <v-list-item :to="item.to" v-else>
              <v-list-item-icon>
                <span v-if="$store.state.user.is_transferable == 1 && item.title == 'Локација' || item.title == 'Lokacija'"><v-icon>{{ item.icon }}</v-icon></span>
                <span v-else-if="item.title != 'Локација' || item.title == 'Lokacija'"><v-icon>{{ item.icon }}</v-icon></span>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="$store.state.user.is_transferable == 1 && item.title == 'Локација' || item.title == 'Lokacija'">{{ translate(item.title) }}</span>
                  <span v-else-if="item.title != 'Локација' || item.title == 'Lokacija'">
                    {{ $t(item.title) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
<!--              <v-list-item-action v-if="$store.state.notification.length && item.title === 'Нотификације'" class="ma-0">-->
<!--                <v-btn fab width="24" height="24" color="primary">{{$store.state.notification.length}}</v-btn>-->
<!--              </v-list-item-action>-->
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left style="background-color: #fff8f5;padding: 0;">
        <v-app-bar-nav-icon
          @click="drawer = !drawer"
          v-if="menu.length > 1 && $store.state.user && ($store.state.restaurant || $store.state.company) && $store.state.user.type != 'guest' && $store.state.user.type != 'recipient' && $store.state.user.type != 'company' && $store.state.user.type != 'customer'"
        ></v-app-bar-nav-icon>

        <div class="pr-2" v-if="$store.state.user && $store.state.user.type != 'guest'">
          <router-link :to="{name:  $store.state.company && $store.state.company.type === 'group' ? 'category-menu' : 'home'}">
            <img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/>
          </router-link>
        </div>
        <div v-else class="pr-2">
          <a href="/#/guest">
          <img style="width: 110px;" :src="'https://app.restorani.bitlab.host/gallery/logo.png'" alt="image"/>
          </a>
        </div>
        <v-toolbar-title style="font-weight: 500;font-size: 1.4rem" v-if="$store.state.user && $store.state.user.type != 'guest' && $store.state.user.type != 'admin_delivery'">{{ translate((site && site.name) || restaurantName) }}</v-toolbar-title>
        <v-btn v-if="$store.state.user && $store.state.user.type != 'guest'" id="dugme_odjava" class="primary mx-4" @click="logout()">
          {{ $t('logout')}}
        </v-btn>
        <div class="menu-items-wrapper">
          <v-select
            v-if="canSeeLanguage"
            class="mr-2 jezik"
            style="width: 10%"
            v-model="$i18n.locale"
            :items="newSelectedRestoran && newSelectedRestoran.settings.language ? newSelectedRestoran.settings.language : [
              {code: 'sr', name: 'Српски - ћирилица', cir_to_lat: 0},
              {code: 'sr-lat', name: 'Srpski - Latinica', cir_to_lat: 1},
              {code: 'rs', name: 'Ћирилица', cir_to_lat: 0},
              {code: 'rs-lat', name: 'Latinica', cir_to_lat: 1},
              {code: 'hr', name: 'Hrvatski', cir_to_lat: 1},
              {code: 'bs', name: 'Bosanski', cir_to_lat: 1},
              {code: 'en', name: 'English', cir_to_lat: 0},
              {code: 'mk', name: 'Македонски', cir_to_lat: 0},
              {code: 'si', name: 'Slovenščina', cir_to_lat: 0},
            ]"
            hide-details
            dense
            item-value="code"
            item-text="name"
            @change="setLang"
          ></v-select>
          <template v-if="$store.state.user && !$store.state.restaurant">
            <v-spacer></v-spacer>
            <div class="mr-4" v-if="$store.state.user.companies[0] && $store.state.user.companies[0].is_credit">
              <span style="font-size: 12px;font-weight: 500" v-if="$store.state.user.credit && $store.state.user.credit.length">
                {{ $t("credit_rest") }}
                <span v-if="$store.state.user.credit && $store.state.user.credit.length"> |
                  {{ $store.state.user.credit && $store.state.user.credit.length ? $store.state.user.credit[$store.state.user.credit.length - 1].duguje : '' }}
                  {{ restaurant && restaurant.settings && restaurant.settings.price && $store.state.user.credit && $store.state.user.credit.length ? restaurant.settings.price : '' }}
                </span>
              </span>
            </div>
            <v-divider vertical class="mr-4"></v-divider>
            <v-btn v-if="$store.state.user && $store.state.user.type == 'guest'" color="#FB6D3B" :to="{ name: 'restorani-guest'}" class="mx-2">
              {{ $t("restaurants") }}
            </v-btn>
            <template v-for="item in menu">
              <v-btn
                v-if="$store.state.user.can_report"
                style="text-transform: capitalize;box-shadow: none;color:#000;border-radius: 12px;font-weight: 600;font-size: 12px;background: none !important;"
                text
                :to="{ name: item.to.name }"
                class="mx-6 naruci_dugme"
              >
                <span v-if='item.title === "Home"'>
                  {{ $t("order") }}
                </span>
                <span v-else-if="$store.state.user.is_transferable == 1 && item.title == 'Локација' || item.title == 'Lokacija'">{{ translate(item.title) }}</span>
                <span v-else-if="item.title != 'Локација' || item.title == 'Lokacija'">{{ $t(item.title) }}</span>
                <span v-else-if="$store.state.user.type == 'recipient'">{{ translate(item.title) }}</span>
<!--                {{ item.title === "Home" ? translate("Наручи") : translate(item.title) }}-->
              </v-btn>
            </template>
            <v-select
              :key="keys"
              class="mr-2 jezik"
              style="width: 2%"
              v-if="newSelectedRestoran && newSelectedRestoran.settings && newSelectedRestoran.settings.language"
              v-model="$i18n.locale"
              :items="newSelectedRestoran.settings.language"
              @change="setLang"
              hide-details
              dense
              item-value="code"
              item-text="name"
            ></v-select>
            <v-select class="mr-2 jezik" style="width: 2%" v-else
              v-model="$i18n.locale"
              :items="[
                {code: 'sr', name: 'Српски - ћирилица', cir_to_lat: 0},
                {code: 'sr-lat', name: 'Srpski - Latinica', cir_to_lat: 1},
                {code: 'rs', name: 'Ћирилица', cir_to_lat: 0},
                {code: 'rs-lat', name: 'Latinica', cir_to_lat: 1},
                {code: 'hr', name: 'Hrvatski', cir_to_lat: 1},
                {code: 'bs', name: 'Bosanski', cir_to_lat: 1},
                {code: 'en', name: 'English', cir_to_lat: 0},
                {code: 'mk', name: 'Македонски', cir_to_lat: 0},
                {code: 'si', name: 'Slovenščina', cir_to_lat: 0},
              ]"
              @change="setLang"
              hide-details
              dense
              item-value="code"
              item-text="name"
            ></v-select>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="" style="background: rgba(251,109,59,0.15);color: #FB6D3B;font-size: 13px" size="50" v-bind="attrs" v-on="on">
                  <span style="" class="text-white">
                    {{ translate($store.state.user.name.charAt(0).toUpperCase()) }} {{ $store.state.user.lastname != null ? translate($store.state.user.lastname.charAt(0).toUpperCase()) : '' }}
                  </span>
                </v-avatar>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in listItems"
                  :key="index"
                  @click="$router.push(item.to)"
                >
                  <v-list-item-title v-if="item.title != 'Одјава'">
                    <v-icon v-html="item.icon" small class="pr-2"></v-icon>
                    {{ $t(item.title) }}
                  </v-list-item-title>
                  <v-list-item-title v-else @click="logout">
                    <v-icon v-html="item.icon" small class="pr-2"></v-icon>
                    {{ translate(item.title) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <v-menu offset-y v-if="$store.state.user && $store.state.user.type && $store.state.user.type == 'report_guest'">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar color="" style="background: rgba(251,109,59,0.15);color: #FB6D3B;font-size: 13px" size="50" v-bind="attrs" v-on="on">
                  <span style="" class="text-white">
                    {{ translate($store.state.user.name.charAt(0).toUpperCase()) }} {{ $store.state.user.lastname != null ? translate($store.state.user.lastname.charAt(0).toUpperCase()) : '' }}
                  </span>
              </v-avatar>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in [
                      { title: 'Почетак', icon: 'mdi-home',to:{ name: 'izvestaj-gost' } },
                      // { title: 'QR код', icon: 'mdi-qrcode ', to: { name: 'qrShow' } },
                      { title: 'Инфо', icon: 'mdi-information ', to: { name: 'info' } },
                    ]"
                  :key="index"
                  @click="$router.push(item.to)"
              >
                <v-list-item-title v-if="item.title != 'Одјава'">
                  <v-icon v-html="item.icon" small class="pr-2"></v-icon>
                  {{ $t(item.title) }}
                </v-list-item-title>
                <v-list-item-title v-else @click="logout">
                  <v-icon v-html="item.icon" small class="pr-2"></v-icon>
                  {{ translate(item.title) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
<!--          CIRILICA LATINICA-->
<!--          <v-btn v-if="langCheck" class=" mx-4" :style="'border: 1px solid' + borderColor"  @click="changeLang()">-->
<!--             {{ $store.state.isLatin ? "Ћир" : "Lat" }}-->
<!--          </v-btn>-->
          <v-btn style="text-transform: capitalize;box-shadow: none;color:#fff;border-radius: 12px;font-weight: 400;font-size: 12px;background:#FB6D3B " v-if="$store.state.user && $store.state.user.type != 'guest'" class="ml-5" @click="logout()">
<!--            {{ translate('Одјава')}}-->
            {{ $t('logout') }}
          </v-btn>
<!--          <v-btn v-else color="#FB6D3B" class="mx-4" @click="logout()">-->
<!--            {{ translate('Одјава')}}-->
<!--          </v-btn>-->
          <v-spacer v-if="websites.length"></v-spacer>

          <div style="width: 260px" class="pr-4" v-if="websites.length > 1 && $store.state.user.type != 'admin_delivery'">
            <v-row no-gutters align="center" class="fill-height">
              <v-autocomplete
                v-model="site"
                :items="websites"
                outlined
                dense
                @change="changeSites"
                hide-details
                item-text="name"
                item-value="id"
                return-object
              ></v-autocomplete>
            </v-row>
          </div>
        </div>
<!--        <v-btn v-if="!$store.state.user" color="#FB6D3B" :to="{ name: 'logovanje' }" class="ml-auto">-->
<!--          {{ translate("Започни") }}-->
<!--        </v-btn>-->
      </v-app-bar>
      <v-main :style="$route.name === 'home' && 'background-color: #fff'">
        <!-- <v-container
          :style="$route.name === 'home' && 'height: 100%; max-width: 100%'"
        > -->
          <router-view></router-view>
        <!-- </v-container> -->
        <v-btn
          v-scroll="onScroll"
          v-show="fab"
          fab
          dark
          fixed
          bottom
          right
          color="primary"
          @click="toTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </v-main>
    </v-app>
    <v-app id="inspire" v-else>
      <router-view></router-view>
    </v-app>
<!--PORUDZBA-->
<!--    <v-snackbar-->
<!--        v-model="$store.state.snackbar"-->
<!--        :timeout="8000"-->
<!--        top-->
<!--        color="red accent-2"-->
<!--    >-->
<!--      <strong>Nova Porudzbina ({{$store.state.notification.length}})</strong>-->
<!--      <template v-slot:action="{ attrs }">-->
<!--        <v-btn-->
<!--            color="#FB6D3B"-->
<!--            text-->
<!--            v-bind="attrs"-->
<!--            @click="$store.commit('SET_SNACKBAR', false)"-->
<!--        >-->
<!--          Close-->
<!--        </v-btn>-->
<!--      </template>-->
<!--    </v-snackbar>-->
  </div>
</template>

<script>
import conf from "./config";
import axios from "@/plugins/axios";
import {db} from "./plugins/firebase";
import restorani from "./pages/Restorani.vue";
import setLang from "./mixins/setLang";
export default {
  mixins: [setLang],
  data: () => ({
    conf: conf,
    site: null,
    fab: false,
    drawer: false,
    childs: [
      { title: "- Лица са уговором", icon: "mdi-file-outline", to: { name: "izvestajFirma" },},
      { title: "- Лица без уговора", icon: "mdi-file-outline", to: { name: "izvestajFirmaBez" },},
      { title: "- Нерегистровани", icon: "mdi-file-outline", to: { name: "izvestajFirmaNeregistrovani" },},
    ],
    items: [
      { title: "order", icon: "mdi-silverware", to: { name: "home" } },
      { title: "meal_main", icon: "mdi-food-fork-drink", to: { name: "jelovnik" },},
      { title: "report", icon: "mdi-file-outline", to: { name: "izvestaj" },},
      // { title: "Извјештај Гост", icon: "mdi-file-outline", to: { name: "izvestaj-gost" },},
      { title: "report", icon: "mdi-file-outline", to: { name: "izvestajFirma" },},
      // { title: "Испорука", icon: "mdi-currency-usd", to: { name: "naplata" } },
      { title: "stock", icon: "mdi-warehouse", to: { name: "magacin" } },
      { title: "companies", icon: "mdi-domain", to: { name: "firme" } },
      { title: "meals_main", icon: "mdi-food-drumstick", to: { name: "jela" } },
      { title: "groups", icon: "mdi-alpha-g-circle-outline", to: { name: "kategorije" },},
      { title: "menu_types", icon: "mdi-alpha-t-circle-outline", to: { name: "menu" },},
      { title: "groceri", icon: "mdi-cart", to: { name: "namirnice" } },
      { title: "gallery", icon: "mdi-file-image ", to: { name: "galerija" } },
        // { title: "Подешавања", icon: "mdi-cog", to: { name: "podesavanja" } },
      { title: "restaurants", icon: "mdi-silverware-fork-knife", to: { name: "restorani" },},
      { title: "history", icon: "mdi-history ", to: { name: "istorija" } },
      // { title: "Нотификације", icon: "mdi-bell ", to: { name: "notification" } },
      /*{title: 'Dokumenta', icon: 'mdi-file-multiple', to: {name: 'dokumenta'}},
          {
              title: 'Kategorije', icon: 'mdi-shape', child: [
                  {title: 'Kategorije dokumenata', to: {name: 'kategorije-dokumenti'}},
                  {title: 'Kategorije objekata', to: {name: 'kategorije-objekti'}},
                  {title: 'Kategorije stranice', to: {name: 'kategorije-stranice'}},
                  {title: 'Kategorije vijesti', to: {name: 'kategorije-vijesti'}},
              ]
          }*/
    ],
    icons: [
      { icon: "mdi-facebook", type: "facebook" },
      { icon: "mdi-twitter", type: "twitter" },
      { icon: "mdi-linkedin", type: "linkedin" },
      { icon: "mdi-instagram", type: "instagram" },
    ],
    windowSize: {
      x: 0,
      y: 0,
    },
    user: '',
    cir: '',
    lat: '',
    langCheck: '',
    prefixLang: 'lat',
    prefix: '',
    country_code: 'lat',
    lang: [
      {code: 'sr', name: 'Српски - ћирилица'},
      {code: 'sr-lat', name: 'Srpski - Latinica'},
      {code: 'rs', name: 'Ћирилица'},
      {code: 'rs-lat', name: 'Latinica'},
      {code: 'hr', name: 'Hrvatski'},
      {code: 'bs', name: 'Bosanski'},
      {code: 'en', name: 'English'},
      {code: 'mk', name: 'Македонски'},
      {code: 'si', name: 'Slovenščina', cir_to_lat: 0},
    ],
    newSelectedRestoran: '',
    canSeeLanguage: false,
    keys: 1
  }),

  methods: {
    async getNotifications() {
      let todosRef = await db
          .collection("orders")
          .orderBy('created_at', 'desc')
      todosRef.onSnapshot(snap => {
        this.$store.commit('SET_NOTIFICATION', [])
        snap.forEach(doc => {
          let data = doc.data()
          data.id = doc.id;
          this.$store.commit('INCREASE_NOTIFICATION', data)
        });
        this.$store.commit('SET_SNACKBAR', true)
      });
    },
    setUser() {
      let lang
      setTimeout(() => {
        if (this.user && this.user.type == 'customer' || this.user && this.user.type == 'company' || this.user && this.user.type == 'Испорука' || this.user && this.user.type == 'recipient' || this.user && this.user.type == 'guest') {
          lang = JSON.parse(this.$store.state.company.restaurant.text_lang)
        } else {
          lang = JSON.parse(this.$store.state.restaurant.text_lang)
        }
        if (lang.lat == 1 && lang.cir == '') {
          this.$store.commit("SAVE_LANGUAGE", 1 - 0);
          this.prefixLang = 'lat'
        } else if (lang.cir == 2 && lang.lat == '') {
          this.$store.commit("SAVE_LANGUAGE", 1 - 1);
          this.prefixLang = 'cir'
        } else if (lang.cir == 2 && lang.lat == 1) {
          this.langCheck = true
          this.prefixLang = 'cir'
        }
      },300)

    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    changeLang() {
      this.$store.commit("SAVE_LANGUAGE", 1 - this.$store.state.isLatin);
      this.prefixLang = this.$store.state.isLatin == 1 ? 'lat' : 'cir'
      this.$root.$emit('prefix_lang', this.prefixLang)
    },
    changeSites() {
      this.$store.commit("SAVE_RESTAURANT", this.site);
      this.refresh = false;
      setTimeout(() => {
        this.refresh = true;
      }, 200);
      this.$root.$emit('changeWebSite', this.site)
    },
    logout() {
      localStorage.removeItem("restoran-token");
      this.$store.commit("SAVE_USER", null);
      this.$store.commit("SAVE_RESTAURANT", null);
      this.$store.commit("SAVE_COMPANY", null);
      this.$router.push({ name: "logovanje" });
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
  computed: {
    restorani() {
      return restorani
    },
    listItems() {
      let routes =  [
        { title: 'profile', icon: 'mdi-account',to:{ name: 'profil' } },
        { title: 'qr_code', icon: 'mdi-qrcode', to: { name: 'qrShow' } },
        // { title: 'report', icon: 'mdi-chart-timeline-variant', to: { name: 'report' } },
        // { title: 'report', icon: 'mdi-chart-timeline-variant', to: { name: 'izvestaj-naplata' } },
        { title: 'info', icon: 'mdi-information', to: { name: 'info' } },
      ]

      if (this.$store.state.company && this.$store.state.company.settings && this.$store.state.company.settings.can_see_report && this.$store.state.company.settings.can_see_report != null) {
        routes.splice(2, 0, { title: "report", icon: "mdi-chart-timeline-variant", to: { name: "izvestaj-naplata" } },)
      } else {
        routes.filter((item,index) => {
          if (item.title == "report") {
            this.items.splice(index, 1)
          }
        })
      }
      if (this.$store.state.user.type == 'guest') {
        routes.push({ title: 'Одјава', icon: 'mdi-logout ', to: this.logout })
      }
      return routes
    },
    websites() {
      return (
        (this.$store.state.user && this.$store.state.user.restaurants) || []
      );
    },
    menu() {
      let arr = [];
      this.items.forEach((i) => {
        if (i.title == 'order') {
          i.to.name = this.$store.state.company && this.$store.state.company.type && this.$store.state.company.type === 'group' ? 'category-menu' : 'home'
        }
        let route = this.$router.options.routes.find(
          (route) => route.name === i.to.name
        );

        if (route && this.$store.state.user && route.permission.includes(this.$store.state.user.type))
          arr.push(i);
      });

      return arr;
    },
    canSeeSettings() {
      let arr = [];
      this.items.forEach((i) => {
        let route = this.$router.options.routes.find(
            (route) => route.name === i.to.name
        );
        if (route && route.permission.includes(this.$store.state.user.type))
          if(this.user.type == 'admin' || (this.user.type == 'restaurant' && this.restaurant.pivot.settings == 1)) {
            arr.push(i);
          }

      });

      return arr;
    },
    borderColor() {
      return this.$store.state.company &&
          this.$store.state.company.restaurant.settings.primary
    },
    topBarColor() {
      return this.site && this.site.settings
        ? this.site.settings.topbar
          ? this.site.settings.topbar.background
          : "primary"
        : this.$store.state.company &&
          this.$store.state.company.restaurant.settings
        ? this.$store.state.company.restaurant.settings.topbar.background
        : "primary";
    },
    restaurantName() {
      return this.site
        ? this.site.name
        : this.$store.state.company
        ? this.$store.state.company.name
        : "";
    },
    restaurant() {
      return this.$store.state.restaurant
        ? this.$store.state.restaurant
        : this.$store.state.company.restaurant;
    },
    settin() {
      return [this.$store.state.restaurant, this.$store.state.user];
    },
  },
  created() {
    this.newSelectedRestoran = JSON.parse(localStorage.getItem('newRes'))
    if (this.newSelectedRestoran && this.newSelectedRestoran.settings.language) {
      let jezik = [];
      for (let i = 0; i < this.newSelectedRestoran.settings.language.length; i++) {
        for (let n = 0; n < this.lang.length; n++) {
          if (this.lang[n].code == this.newSelectedRestoran.settings.language[i]) {
            jezik.push(this.lang[n])
          }
        }
      }
      this.newSelectedRestoran.settings.language = jezik
    }

    this.$root.$on('test', (data) => {
      if (data) {
        let d = JSON.parse(data)
        if (d && d.settings && d.settings.language) {
          let jezik = [];
          for (let i = 0; i < d.settings.language.length; i++) {
            for (let n = 0; n < this.lang.length; n++) {
              if (this.lang[n].code == d.settings.language[i]) {
                jezik.push(this.lang[n])
              }
            }
          }
          this.newSelectedRestoran.settings.language = jezik
        }
      }
    })
    this.$root.$on('setUser', () => {
      this.setUser()
    })
    this.$root.$emit('prefix_lang', this.prefixLang)
    this.$root.$on('langChanged', ({data}) => {
      let lang = JSON.parse(data.data.text_lang)
      if (lang.cir == 2 && lang.lat == '') {
        this.langCheck = false
        this.prefixLang = 'cir'
        this.$store.commit("SAVE_LANGUAGE", 1 - 1);
      } else if (lang.lat == 1 && lang.cir == '') {
        this.prefixLang = 'lat'
        this.langCheck = false
        this.$store.commit("SAVE_LANGUAGE", 1 - 0);
      } else if (lang.cir == 2 && lang.lat == 1) {
        this.langCheck = true
        this.$store.commit("SAVE_LANGUAGE", 1 - 1);
        this.prefixLang = 'cir'
      }
    })
    this.getNotifications()
  },
  mounted() {
    this.$store.commit('SET_DRAWER', this.drawer)
    this.onResize()
    this.getLang()
  },

  watch: {
    '$i18n.locale'() {
      if(this.$i18n.locale == 'rs-lat' || this.$i18n.locale == 'sr-lat') {
        this.$store.state.isLatin = 0
        this.$store.commit("SAVE_LANGUAGE", 1 - this.$store.state.isLatin);
        this.prefixLang = 'lat'
        this.$root.$emit('prefix_lang', this.prefixLang)
      }
      if(this.$i18n.locale == 'rs' || this.$i18n.locale == 'sr') {
        this.$store.state.isLatin = 1
        this.$store.commit("SAVE_LANGUAGE", 1 - this.$store.state.isLatin);
        this.prefixLang = 'cir'
        this.$root.$emit('prefix_lang', this.prefixLang)
      }
      if(this.$i18n.locale == 'hr') {
          this.$store.state.isLatin = 0
          this.$store.commit("SAVE_LANGUAGE", 1 - this.$store.state.isLatin);
          this.prefixLang = 'lat'
          this.$root.$emit('prefix_lang', this.prefixLang)
      }
    },
    drawer() {
      this.$store.commit('SET_DRAWER', this.drawer)
    },
    "$store.state.user"() {
      this.user = this.$store.state.user
      this.$root.$emit('setUser', this.user)
      if (this.$store.state.user && this.$store.state.user.type == 'recipient' && this.$store.state.user.is_contract == 1) {
        this.items.splice(5, 0, { title: "delivery", icon: "mdi-currency-usd", to: { name: "naplata" } },)
      } else {
        this.items.filter((item,index) => {
          if (item.title == "Испорука" || item.title == "delivery") {
            this.items.splice(index, 1)
          }
        })
      }
      // can see language
      if (this.$store.state.user && this.$store.state.user.type == 'admin' || this.$store.state.user && this.$store.state.user.type == 'restaurant') {
        this.canSeeLanguage = true
      } else {
        this.canSeeLanguage = false
      }

      if (this.$store.state.user && this.$store.state.user.type != 'company') {
        this.items.splice(6,0, { title: "companies_delivery", icon: "mdi-domain", to: { name: "firme-dostava" } })
      }
      else {
        this.items.filter((item,index) => {
          console.log(item.title)
          if (item.title == "companies_delivery") {
            this.items.splice(index, 1)
          }
        })
      }

      if (this.$store.state.user && this.$store.state.user.type == 'recipient' && this.$store.state.user.is_non_contract == 1) {
        this.items.splice(5, 0, { title: "Isporuka-za-neregistrovane", icon: "mdi-currency-usd", to: { name: "guest-home" } },)
      } else {
        this.items.filter((item,index) => {
          if (item.title == "Isporuka-za-neregistrovane") {
            this.items.splice(index, 1)
          }
        })
      }
      if (this.$store.state.user && this.$store.state.user.type == 'customer') {
        let languages = []
        if (! this.newSelectedRestoran) {
          this.newSelectedRestoran = {}
          this.newSelectedRestoran['settings'] = {}
          this.newSelectedRestoran['settings']['language'] = {}
        }

        this.lang.filter(lang => {
          setTimeout(() => {
            if (this.$store.state.company.restaurant.settings && this.$store.state.company.restaurant.settings.language) {
              this.$store.state.company.restaurant.settings.language.filter(lan => {
                if (lan == lang.code) {
                  languages.push(lang)
                }
              })
            }
          }, 500)
        })
        this.newSelectedRestoran.settings.language = languages
        this.$forceUpdate()
      }
      if (this.$store.state.company && this.$store.state.company.restaurant.settings.language) {
        this.keys++
        let languages = []
        this.lang.filter(lang => {
          this.$store.state.company.restaurant.settings.language.filter(lan => {
            if (lan == lang.code) {
              languages.push(lang)
            }
          })
        })
        if (! this.newSelectedRestoran) {
          this.newSelectedRestoran = {}
          this.newSelectedRestoran['settings'] = {}
          this.newSelectedRestoran['settings']['language'] = {}
        }
        this.newSelectedRestoran.settings.language = languages
        this.$i18n.locale = this.$store.state.company.restaurant.settings.language[0]
      }
      if (this.canSeeLanguage) {
        this.$forceUpdate()
      }
      if (this.user && (this.user.type == 'dostava'|| this.user.type == 'admin_delivery')) {
        this.items.push(
            { title: "orders", icon: "mdi-domain", to: { name: "delivery-user-orders" } },
        )
      }
      if (this.$store.state.user && this.$store.state.user.type == 'shop') {
        this.items = []
        this.items.push(
            { title: "report", icon: "mdi-file-outline", to: { name: "izvestajFirma" },},
            { title: "meals_main", icon: "mdi-food-drumstick", to: { name: "jela" } },
            { title: "groups", icon: "mdi-alpha-g-circle-outline", to: { name: "kategorije" },},
        )
      }
    },
    "$store.state.company"() {
      if(this.$store.state.company && this.$store.state.company.is_delivery==1){
        this.items[0].to={name:'delivery'};
      }else{
        this.items[0].to={name:'home'};
      }
      let index = this.items.findIndex(
        (item) => item.title === "Локација"
      )
      if(index<0&&this.$store.state.company&&this.$store.state.company.parent_id&&this.$store.state.user.type!=3){
        this.items.push({ title: "Локација", icon: "mdi-map-marker ", to: { name: "lokacija" } })
      }
      let index2=this.items.findIndex(
        (item)=>item.title==="Пословнице"
      )
      let index3=this.items.findIndex(
        (item)=>item.title==="Корисници"
      )
      if(index2<0&&( this.$store.state.company && this.$store.state.company.children.length>0)){
        this.items.push({title:"Пословнице", icon: "mdi-file-outline",to:{name:"poslovnice"}});
      }else if(index3<0){
        this.items.push({title: this.$t('users'), icon: "mdi-file-outline",to:{name:"korisnici" }});
      }
      // if (this.restaurant.settings.language.length) {
      //   this.$i18n.locale = this.restaurant.settings.language[0]
      // }

      if (this.$store.state.company && this.$store.state.company.restaurant.settings.language) {
        let languages = []
        this.keys++
        this.lang.filter(lang => {
          this.$store.state.company.restaurant.settings.language.filter(lan => {
            if (lan == lang.code) {
              languages.push(lang)
            }
          })
        })
        if (! this.newSelectedRestoran) {
          this.newSelectedRestoran = {}
          this.newSelectedRestoran['settings'] = {}
          this.newSelectedRestoran['settings']['language'] = {}
        }
        this.newSelectedRestoran.settings.language = languages
        this.$i18n.locale = this.$store.state.company.restaurant.settings.language[0]
      }
      this.$vuetify.theme.themes.light.primary =
        this.$store.state.company &&
        this.$store.state.company.restaurant.settings
          ? this.$store.state.company.restaurant.settings.primary
          : "#54555b";
    },
    "$store.state.restaurant"() {
      this.site = this.$store.state.restaurant;
      let index = this.items.findIndex(
        (item) => item.title === "Корисници изабраног ресторана"
      );

      if (this.$store.state.user && this.$store.state.user.type == 'restaurant' || this.$store.state.user && this.$store.state.user.type == 'admin') {
        let languages = []
        if (! this.newSelectedRestoran) {
          this.newSelectedRestoran = {}
          this.newSelectedRestoran['settings'] = {}
          this.newSelectedRestoran['settings']['language'] = {}
        }
        this.lang.filter(lang => {
          if (this.$store.state.restaurant && this.$store.state.restaurant.settings.language) {
            this.$store.state.restaurant.settings.language.filter(lan => {
              if (lan == lang.code) {
                languages.push(lang)
              }
            })
          }
        })
        this.newSelectedRestoran.settings.language = languages
        this.$forceUpdate()
      }
      if (this.site && this.site.settings.lice_bez_ugovora) {
        this.items.splice(3, 0, { title: "guest_report", icon: "mdi-file-outline", to: { name: "izvestaj-gost" },})
      } else {
        this.items.filter((item,index) => {
          if (item.title == "guest_report") {
            this.items.splice(index, 1)
          }
        })
      }

      this.$vuetify.theme.themes.light.primary = this.site.settings
        ? this.site.settings.primary
        : "#54555b";
      if (index > -1) {
        this.items[index].to.params.id = this.$store.state.user.restaurants[0].id;
        this.$router.push({ name: "home" });
      } else {
        let moze = true
        this.items.filter((item,index) => {
          if (item.title == "customers_restoran") {
            moze = false
            this.items[index].to.params.id = this.$store.state.user.restaurants[0].id;
          }
        })
        if (moze) {
          this.$forceUpdate()
          this.items.push({
            title: "customers_restoran",
            icon: "mdi-domain",
            to: {
              name: "restoran-id-korisnici",
              params: { id: this.$store.state.user.restaurants[0].id || 0 },
            },
          });
        }
      }
      // if((this.$store.state.user.type == 'restaurant' && this.$store.state.restaurant.pivot.settings == 1) || this.$store.state.user.type == 'admin') {
      //   this.menu.splice(10, 0, { title: "Подешавања", icon: "mdi-cog", to: { name: "podesavanja" } });
      // }

      // if(this.$store.state.restaurant.pivot.settings == 1) {
      //   this.$store.state.restaurant.users.filter(user => {
      //     if (user.type == 'restaurant' || user.type == 'admin') {
      //       this.menu.splice(10, 0, { title: "Подешавања", icon: "mdi-cog", to: { name: "podesavanja" } });
      //     }
      //   })
      // }
      this.keys = 1
      if (this.restaurant.settings.language) {
        this.$i18n.locale = this.restaurant.settings.language[0]
        this.$forceUpdate()
      }
    },
    settin(newVal, oldVal) {
     if(newVal) {
       if((this.$store.state.user && this.$store.state.user.type == 'restaurant' && this.$store.state.restaurant.pivot.settings == 1) || this.$store.state.user && this.$store.state.user.type == 'admin') {
         let ima = false
         this.menu.filter(item => {
           if (item.title == 'Подешавања' || item.title == 'Podesavanja') {
             ima = true
           }
         })
         if (!ima) {
           this.menu.splice(10, 0, { title: "settings", icon: "mdi-cog", to: { name: "podesavanja" } });
         }
       }
       if(this.$store.state.user && this.$store.state.user.type == 'admin') {
         let ima = false
         this.menu.filter(item => {
           if (item.title == 'Подешавања' || item.title == 'Podesavanja') {
             ima = true
           }
         })
         if (!ima) {
           this.menu.splice(14, 0, { title: "messages", icon: "mdi-message", to: { name: "poruke" } });
         }
       }
     }
    },
  },
};
</script>



<style>
html,body, .v-application {
  font-family: 'Poppins', Helvetica, sans-serif !important;
}
.naruci_dugme::before {
  content: none;
}
header {
  height: 64px !important;
}
.jezik .v-input__slot::before {
  content: none !important;
}
.jezik .v-input__slot {
  font-weight: 700 !important;
  font-size: 14px !important;
}
.container {
  padding-bottom: 100px !important;
}
.v-application--is-ltr .v-input--selection-controls__input {
  display: unset !important;
}
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
   color: #252525 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  cursor: pointer!important;

}
@font-face {
  font-family: "Gagalin";
  src: local("Gagalin"),
  url('../src/assets/Gagalin-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
  url('../src/assets/Poppins-Regular.ttf') format("truetype");
}

.menu-items-wrapper {
      display: flex;
    align-items: center;
    margin-left: auto;
}

.menu-icon-mobile {
  display: none !important;
}


img.hero-logo {
    height: 100%;
}

.hero {
  height: 300px;
  display: flex;
  justify-content: center;
  margin-top: -4px;
}
#dugme_odjava {
  display: none;
}
@media(max-width: 767px) {
  .menu-items-wrapper {
    display: none;
  }

  .menu-icon-mobile {
    display: block !important;
    margin-left: auto;
  }
  #dugme_odjava {
    display: block;
    margin-left: auto !important;
  }
}

</style>
