<template>
  <div style="width: 95%;margin: 10px auto">
    <v-data-table
            :headers="headers"
            :items="filterRestoransByEntity"
            sort-by="calories"
            class="elevation-1"
            dense
            :search="search"
            show-expand>
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
          {{ $t(header.text) }}
        </template>
        <template v-slot:item.data-table-expand="{item, isExpanded, expand}">
          <template v-if="item.children && item.children.length">
            <v-btn icon @click="expand(true)" v-if="!isExpanded">
                <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon @click="expand(false)" v-else>
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{$t ('restaurants_list')}}</v-toolbar-title>
            <v-divider
                    class="mx-4"
                    inset
                    vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-select style="margin-top: 9px;margin-right: 20px" v-if="$store.state.user.type == 'admin'"
                :items="[
                   {text: translate('Све'), val: 0},
                   {text: translate('Ресторан'), val: 1},
                   {text: translate('Продавница'), val: 2},
                ]"
                item-value="val"
                v-model="entity"
                :label="translate('Филтер Ресторан / Продавница')"
                line
                @change="filterEntity"
            >
            </v-select>
            <v-text-field v-if="$store.state.user.type == 'admin'"
                class="pr-4 pb-2"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="730px" scrollable style="border-radius: 12px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" v-if="getRole && getRole.pivot.add_restoran == 1 && getRole.type == 'restaurant' || user.type == 'admin'"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                     <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-card class="dialog-restoran" style="border-radius: 12px">
                    <v-card-title class="primary" style="display: flex;justify-content: space-between">
                        <span class="headline white--text">
                          {{ $t(formTitle) }}
                        </span>
                      <span @click.prevent="dialog = false" style="color: #fff;padding: 1px 12px;border-radius: 50px;border:1px solid;cursor: pointer">
                        x
                      </span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                              <v-row style="margin-top: 15px;margin-left: 10px;align-items: center">
                                <label style="margin-bottom: 10px;font-weight: 700;margin-right: 10px" for="">{{ translate('Тип') }}:</label>
                                <v-radio-group style="display: flex" v-model="editedItem.entity_type" inline>
                                  <v-radio inline :label="translate('Ресторан')" :value="1"></v-radio>
                                  <v-radio inline :label="translate('Продавница')" :value="2"></v-radio>
                                </v-radio-group>
                              </v-row>

                                <v-col cols="12" class="mb-0 pb-0">
                                    <v-text-field
                                            v-model="editedItem.name"
                                            :label="$t('*title')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="mb-0 pb-0">
                                    <v-select
                                            v-model="editedItem.parent"
                                            :items="userRestorans.filter(item => item.id)"
                                            :label="$t('parent_restaurant')"
                                            item-text="name"
                                            item-id="id"
                                            return-object
                                    ></v-select>
                                </v-col>
                              <v-row style="padding: 0 15px">
                                <v-col cols="6" class="mb-0 pb-0">
                                  <v-text-field
                                      v-model="editedItem.email"
                                      :label="'* ' + $t('email')"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="mb-0 pb-0">
                                    <v-text-field
                                            v-model="editedItem.phone"
                                            :label="'* ' + $t('phone')"
                                    ></v-text-field>
                                </v-col>
                              </v-row>

                                <v-col cols="12" class="mb-0 pb-0">
                                  <v-row>
                                    <v-col cols="6" class="mb-0 pb-0">
                                      <v-select
                                          @change="getCity()"
                                          v-model="editedItem.country"
                                          :items="countries"
                                          :label="'* ' + $t('country')"
                                          item-text="name"
                                          item-id="id"
                                          return-object
                                      ></v-select>
                                    </v-col>
                                    <v-col cols="6" class="mb-0 pb-0">
                                      <v-select
                                          v-model="editedItem.city"
                                          :items="cities ? cities : []"
                                          :label="'* ' + $t('city')"
                                          item-text="name"
                                          item-id="id"
                                          return-object
                                      ></v-select>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" class="mb-0 pb-0">
                                  <v-text-field
                                      v-model="editedItem.address"
                                      :label="'* ' + $t('address')">
                                  </v-text-field>
                                </v-col>
                                <v-col cols="12" class="mb-0 pb-0">
                                <v-row>
                                  <v-col cols="4" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="editedItem.latitude"
                                        :label="$t('lati1')">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="4" class="mb-0 pb-0">
                                    <v-text-field
                                        v-model="editedItem.longitude"
                                        :label="$t('long1')">
                                    </v-text-field>
                                  </v-col>
                                  <v-col cols="4" class="mb-0 pb-0">
                                    <v-text-field onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                                        v-model="editedItem.delivery_distance"
                                        type="number"
                                        :label="$t('delivery_distance')">
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col cols="12" class="mb-0 pb-0">
                               <v-row>
                                <v-col cols="6">
                                  <v-text-field
                                    v-model="editedItem.lib"
                                    :label="$t('jib')"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field
                                    v-model="editedItem.pib"
                                    :label="$t('pib')"
                                  ></v-text-field>
                                </v-col>
                               </v-row>
                              </v-col>
                              <v-row class="px-3">
                                <v-col cols="6" class="mb-0 pb-0">
                                  <v-text-field
                                      v-model="editedItem.owner"
                                      :label="$t('owner')"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="6" class="mb-0 pb-0">
                                  <v-text-field
                                      v-model="editedItem.contact_person"
                                      :label="$t('contact_person')"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-col cols="12" class="mb-4 pb-0">
                                <h4 class="mb-6">{{ $t('pay_type') }}</h4>
                                <div style="display: flex;justify-content: space-between">
                                  <v-switch
                                      v-model="editedItem.restoran_tip_ugovora.paying_type"
                                      inset
                                      :label="$t('by_month')"
                                      :false-value="0"
                                      :true-value="1"
                                      @change="changeAmountMonth()"
                                      dense
                                  ></v-switch>
                                  <v-switch
                                      v-model="editedItem.restoran_tip_ugovora.paying_type"
                                      inset
                                      :label="$t('by_percent')"
                                      @change="changeAmountPercent()"
                                      :false-value="0"
                                      :true-value="2"
                                      dense
                                  ></v-switch>
                                  <v-switch
                                      v-model="editedItem.restoran_tip_ugovora.paying_type"
                                      inset
                                      :label="$t('by_meal')"
                                      @change="changeAmountMeal()"
                                      :false-value="0"
                                      :true-value="3"
                                      dense
                                  ></v-switch>
                                </div>
                                <span v-if="editedItem.restoran_tip_ugovora.paying_type">
                                    <v-text-field type="number" style="width: 30%" v-model="editedItem.restoran_tip_ugovora.amount" :label="$t('amount') + ' ' + (editedItem.restoran_tip_ugovora.paying_type == 2 ? '%' : ($store.state.restaurant.settings.price ? $store.state.restaurant.settings.price : ''))"></v-text-field>
                                  </span>
                              </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          style="border-radius: 8px;background: none;"
                          text
                          color="primary"
                          class="px-5"
                          @click="close"
                        >
                            {{$t ('cancel')}}
                        </v-btn>
                        <v-btn
                          style="border-radius: 8px;"
                          color="primary darken-1 px-5"
                          dark
                          @click="save"
                        >
                            {{$t ('save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                      <v-card-title class="headline">{{translate ('Потврдите брисање')}}</v-card-title>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t ('confirm')}}</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
              </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{item, isExpanded, expand}">
          <template v-if="item.children">
            <td :colspan="headers.length" class="px-0">
              <v-data-table
                      dense
                      :headers="headers"
                      class="grey lighten-4"
                      :items="item.children || []"
                      hide-default-footer
                      disable-pagination
                      hide-default-header>
                <template v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" v-slot:item.restoran="{ item, index }">
                  <v-checkbox v-model="item.parent_menu" @change="setParentMenu(item, index)"></v-checkbox>
                </template>
                <template v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" v-slot:item.settings="{ item, index }">
                  <div class="text-right">
                    <v-checkbox v-model="item.add_meal" @change="setAddMeal(item, index)"></v-checkbox>
                  </div>

                </template>
                <template v-slot:item.actions="{ item, index }">
                  <v-btn small color="primary" text class="mx-2" :to="{name: 'restoran-id-korisnici', params: {id: item.id}}">
                      {{$t('users')}} {{item.users_count}}
                  </v-btn>
                  <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                      mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </td>
          </template>
        </template>
        <template v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" v-slot:item.restoran="{ item, index }"></template>
        <template v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" v-slot:item.settings="{ item, index }"></template>
        <template v-slot:item.actions="{ item }">
          <v-btn small color="primary" text class="mx-2" :to="{name: 'restoran-id-korisnici', params: {id: item.id}}" v-if="(item.children && item.children.length == 0) || !item.hasOwnProperty('children')">
              {{$t('users')}} {{item.users_count}}
          </v-btn>
          <v-btn small color="primary" text class="mx-2" v-else :to="{name: 'restoran-id-korisnici', params: {id: item.id}}">
              {{$t('users')}} {{ item.users_count}}
          </v-btn>
          <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
              mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" v-if="getRole && getRole.pivot.add_restoran == 1 && getRole.type == 'restaurant' || user.type == 'admin'"
                  @click="initialize">
              {{$t ('resets')}}
          </v-btn>
        </template>
    </v-data-table>
  </div>
</template>

<script>
    import axios from '../plugins/axios'

    export default {
        data: () => ({
            dialog: false,
            dialogDelete: false,
          search: '',
            parent_menu: [],
            entity: '',
            add_meal: [],
            headers: [
                {
                  text: 'Id',
                  align: 'start',
                  value: 'id',
                  sortable: true
                },
                {
                    text: 'title',
                    align: 'start',
                    value: 'name',
                },
                {
                    text: 'address',
                    align: 'start',
                    value: 'address',
                    sortable: false
                },
                {
                    text: 'phone',
                    align: 'end',
                    value: 'phone',
                    sortable: false
                },
                {
                    text: 'email',
                    align: 'start',
                    value: 'email',
                    sortable: false
                },
                // {
                //     text: 'ЈИБ',
                //     align: 'end',
                //     value: 'lib',
                //     sortable: false
                // },
                // {
                //     text: 'ПИБ',
                //     align: 'end',
                //     value: 'pib',
                //     sortable: false
                // },
                { text: 'actions', align: 'center', value: 'actions', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            desserts: [],
            editedIndex: -1,
            editedParentIndex: -1,
            editedItem: {
                restoran_tip_ugovora: {
                  paying_type: '',
                  amount: '',
                },
                name: '',
                owner: '',
                paying_type: '',
                contact_person: '',
                amount: '',
                entity_type: '',
                address: '',
                phone: '',
                email: '',
                lib: '',
                pib: '',
                country: [],
                city: [],
                city_id: [],
                latitude: '',
                longitude: '',
                delivery_distance: '',
            },
            defaultItem: {
                restoran_tip_ugovora: {
                  paying_type: '',
                  amount: '',
                },
                name: '',
                owner: '',
                paying_type: '',
                contact_person: '',
                amount: '',
                entity_type: '',
                address: '',
                phone: '',
                email: '',
                lib: '',
                pib: '',
                country: [],
                city: [],
                latitude: '',
                longitude: '',
                delivery_distance: ''
            },
           user: '',
           userRestorans: [],
          countries: '',
          cities: ''
        }),

        computed: {
          filterRestoransByEntity() {
            let res = this.userRestorans
            let data = []
            res.filter(item => {
              if (item.entity_type == this.entity) {
                data.push(item)
              }
            })
            if (this.entity == '') {
              return res
            } else if (this.entity == 0) {
              return res
            } else {
              return data
            }
          },
          formTitle () {
              return this.editedIndex === -1 ? this.$t('new_restaurant') : this.$t('restaurant') + ': ' + this.editedItem.id
          },
          getRole() {
            let usr = ''
            this.user.restaurants.filter(restoran => {
              restoran.users.filter(user => {
                if (user.id == this.user.id) {
                  usr = user
                }
              })
            })
            return usr
          }
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        created () {
          this.getCountry()
          this.initialize()
          this.user = this.$store.state.user;
          if(this.$store.state.user.type == 'restaurant' || this.$store.state.user.type == 'admin') {
            this.headers.splice(5, 0, {
                text: this.$t('parent_m'),
                align: "start",
                value: "restoran",
                sortable: false,
              },
              {
                text: "+ " + this.$t('add_meals'),
                align: "start",
                value: "settings",
                sortable: false,
              });
          }
        },

        methods: {
          filterEntity() {
            console.log(this.entity)
          },
          changeAmountMonth() {
            if (this.editedItem.by_month == 1) {
              this.editedItem.by_month = 1
            } else {
              this.editedItem.by_month = 0
            }
            this.editedItem.by_percent = 0
            this.editedItem.by_meal = 0
            this.editedItem.by_percent_input = ''
            this.editedItem.by_meal_input = ''
            this.$forceUpdate()
          },
          changeAmountPercent() {
            this.editedItem.by_meal = 0
            this.editedItem.by_month = 0
            if (this.editedItem.by_percent == 2) {
              this.editedItem.by_percent = 2
            } else {
              this.editedItem.by_percent = 0
            }
            this.editedItem.by_month_input = ''
            this.editedItem.by_meal_input = ''
            this.$forceUpdate()
          },
          changeAmountMeal() {
            if (this.editedItem.by_meal == 3) {
              this.editedItem.by_meal = 3
            } else {
              this.editedItem.by_meal = 0
            }
            this.editedItem.by_month = 0
            this.editedItem.by_percent = 0
            this.editedItem.by_month_input = ''
            this.editedItem.by_percent_input = ''
            this.$forceUpdate()
          },
          getCity() {
            this.cityes = []
            let cityes = []
            this.countries.filter(item => {
              item.cities.filter(city => {
                this.editedItem.country.cities.filter(c => {
                  if (city.id == c.id) {
                    cityes.push(city)
                  }
                })
              })
            })
            this.cities = cityes
          },
          getCountry() {
            axios.get('/restaurants/countries-all').then(({data}) => {
              this.countries = data
            })
          },
            setParentMenu(item, index) {
              axios.post("/restaurants/add-parent-menu", item).then(({data}) => {
                console.log('OK')
              });
            },
            setAddMeal(item, index) {
              axios.post("/restaurants/can-set-meal", item).then(({data}) => {
                console.log('OK')
              });
            },
            initialize () {
              this.userRestorans = []
              if (this.$store.state.user.type == 'dostava') {
                axios.get('/restaurants?user_id=' + this.$store.state.user.id).then(res => {
                  this.desserts = res.data
                  res.data.filter(restorans => {
                    this.userRestorans.push(restorans)
                  })
                })
              } else {
                axios.get('/restaurants').then(res => {
                  this.desserts = res.data
                  res.data.filter((restoran,index) => {
                    this.parent_menu[index] = false
                    this.add_meal[index] = false
                  })
                  if(this.getRole) {
                    if(this.getRole.pivot.add_restoran == 1 && this.getRole.type == "restaurant") {
                      if(this.user.restaurants[0].parent_id == 0) {
                        this.desserts.filter(restorans => {
                          if ([this.user.restaurants[0].id].includes(restorans.id)) {
                            this.userRestorans.push(restorans)
                          }
                        })
                      } else {
                        this.userRestorans = this.user.restaurants
                      }
                      // this.userRestorans = this.user.restaurants
                      this.userRestorans.filter(restoran => {
                        restoran.users_count = restoran.users.length
                      })
                    } else {
                      this.desserts.filter(restorans => {
                        if ([this.user.restaurants[0].id].includes(restorans.id)) {
                          this.userRestorans.push(restorans)
                        }
                      })
                      // this.userRestorans = this.user.restaurants
                      this.userRestorans.filter(restoran => {
                        restoran.users_count = restoran.users.length
                      })
                    }
                  } else if (this.user.type == 'admin') {
                    this.userRestorans = this.desserts
                  }
                })
              }
            },

            editItem (item) {
              if(item.parent_id) {
                this.editedIndex = this.desserts.findIndex(i => i.id === item.parent_id)
                this.editedParentIndex = this.desserts[this.editedIndex].children.findIndex(i => i.id = item.id)
              }
              else {
                let inx = ''
                this.desserts.filter((des,index) => {
                  if (des.id == item.id) {
                    inx = index
                  }
                })
                  // this.editedIndex = this.desserts.indexOf(item)
                  this.editedIndex = inx
              }
              let cityes = []
              this.countries.filter(country => {
                country.cities.filter(city => {
                  if (item.city) {
                    if (item.city instanceof Object) {
                      if (city.country_id == item.city.country.id) {
                        cityes.push(city)
                      }
                    } else {
                      if (city.country_id == item.city) {
                        cityes.push(city)
                      }
                    }
                  }
                })
              })
              item.entity_type = parseInt(item.entity_type)
              this.cities = cityes.length ? cityes : []
              item.country = item.city ? item.city.country : []
              if (item.restoran_tip_ugovora) {
                item.restoran_tip_ugovora.paying_type = parseFloat(item.restoran_tip_ugovora.paying_type)
              } else {
                item.restoran_tip_ugovora = {}
                item.restoran_tip_ugovora.paying_type = ''
                item.restoran_tip_ugovora.amount = ''
              }
              if (typeof item.city !== 'object') {
                item.city = this.cities.find(id => id.id == item.city_id)
              }
              if (typeof item.country == 'undefined') {
                // item.country = this.countries.find(id => id.id == item.city.country_id)
                item.country = this.countries
              }
              this.editedItem = Object.assign({}, item)
              this.dialog = true
              this.$forceUpdate()
            },

            deleteItem (item) {
                if(item.parent_id) {
                    this.editedIndex = this.desserts.findIndex(i => i.id === item.parent_id)
                    this.editedParentIndex = this.desserts[this.editedIndex].children.findIndex(i => i.id = item.id)
                }
                else {
                    this.editedIndex = this.desserts.indexOf(item)
                }
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
              axios.delete('/restaurants/' + this.editedItem.id).then(res => {
                if(this.editedParentIndex > -1) {
                  this.desserts[this.editedIndex].children.splice(this.editedParentIndex, 1)
                }
                else {
                  this.desserts.splice(this.editedIndex, 1)
                }
                this.closeDelete()
              })
            },

            close () {
              this.dialog = false
              this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.editedParentIndex = -1
              })
            },

            closeDelete () {
              this.dialogDelete = false
              this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.editedParentIndex = -1
              })
            },

            save () {
              this.editedItem.parent_id = this.editedItem.parent ? this.editedItem.parent.id : 0
              this.editedItem.city = this.editedItem.city.id
              this.editedItem.city_id = this.editedItem.city
              this.editedItem.country = this.editedItem.country.id
              if (this.editedIndex > -1 || this.editedParentIndex > -1) {
                let _editedIndex = this.editedIndex
                let _editedParentIndex = this.editedParentIndex
                let _editedItem = this.editedItem
                axios.put('/restaurants/' + this.editedItem.id, this.editedItem).then(res => {
                  if(_editedParentIndex > -1) {
                    Object.assign(this.desserts[_editedIndex].children[_editedParentIndex], _editedItem)
                  }
                  else {
                    Object.assign(this.desserts[_editedIndex], _editedItem)
                  }
                  this.initialize()
                })
              } else {
                let _editedItem = this.editedItem
                axios.post('/restaurants', this.editedItem).then(res => {
                  _editedItem.id = res.data.id
                  if(_editedItem.parent_id) {
                    let i = this.desserts.findIndex(i => i.id === _editedItem.parent_id)
                    this.desserts[i].children.push(_editedItem)
                  }
                  else{
                    this.desserts.push(_editedItem)
                  }
                })
              }
              this.close()
            },
        },
    }
</script>
<style>
  .dialog-restoran .v-card__text .container {
    padding-bottom: 10px !important;
  }
</style>